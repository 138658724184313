var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c("deposited-cash-entries", {
                    ref: _vm.tableRef,
                    attrs: {
                      fields: _vm.fields,
                      repository: _vm.repository,
                      resource: _vm.resource,
                      filterName: _vm.filterName,
                      onlyActions: ["destroy", "edit"],
                      filterOn: {
                        byRelations: ["byCashEntryHistory"],
                      },
                      noInnerWidth: "",
                      noPagination: "",
                      noInputPaginator: "",
                    },
                    on: { edit: _vm.onEdit, destroy: _vm.onDestroy },
                  }),
                  _vm._v(" "),
                  _c("edit-deposited-cash-entry-modal", {
                    on: { save: _vm.onUpdateEntryDetail },
                    model: {
                      value: _vm.item,
                      callback: function ($$v) {
                        _vm.item = $$v
                      },
                      expression: "item",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _vm._l(_vm.detailFields, function (item, index) {
                    return _c("details-card", {
                      key: index,
                      attrs: {
                        header: item.header,
                        edit: item.edit || false,
                        fields: item.fields,
                        beForm: _vm.beForm,
                        data: _vm.details,
                        repository: item.repository,
                      },
                    })
                  }),
                  _vm._v(" "),
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ invalid, handleSubmit }) {
                            return [
                              _c(
                                "b-card",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    header: "true",
                                    "header-tag": "header",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-center",
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-0 d-inline-block align-middle",
                                        },
                                        [_c("b", [_vm._v("Recupero Acconto")])]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.details.gross + _vm.details.reduced != 0
                                    ? _c(
                                        "div",
                                        [
                                          _c("b-row", [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c("base-select", {
                                                  attrs: {
                                                    vid: "cash_reference_id",
                                                    name: "causale",
                                                    label: "Causale",
                                                    options:
                                                      _vm.cash_references_opt,
                                                    taggable: false,
                                                    multiple: false,
                                                    closeOnSelect: true,
                                                    rules: { required: true },
                                                    preselectFirst: true,
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.onCashReferenceInput,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .cash_reference_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "cash_reference_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.cash_reference_id",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c("base-datepicker", {
                                                  attrs: {
                                                    vid: "valuta_da",
                                                    name: "Data Recupero Acconto",
                                                    label:
                                                      "Data Recupero Acconto",
                                                    min: _vm.minDate,
                                                    rules: { required: true },
                                                  },
                                                  on: {
                                                    select:
                                                      _vm.onDetailDateSelect,
                                                  },
                                                  model: {
                                                    value: _vm.form.cash_time,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "cash_time",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.cash_time",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c("base-select", {
                                                  attrs: {
                                                    vid: "broker_id",
                                                    name: "produttore",
                                                    label: "Produttore",
                                                    options: _vm.getSalesmen(),
                                                    taggable: false,
                                                    multiple: false,
                                                    closeOnSelect: true,
                                                    rules: _vm.has_broker,
                                                  },
                                                  model: {
                                                    value: _vm.form.broker_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "broker_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.broker_id",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c("base-currency", {
                                                  attrs: {
                                                    name: "importo",
                                                    vid: "gross",
                                                    label: "Importo",
                                                    options: {
                                                      currency: "EUR",
                                                      locale: "it-IT",
                                                      precision: 2,
                                                    },
                                                    rules: {
                                                      required: true,
                                                      compare_number: {
                                                        otherValue: "0",
                                                        operator: ">",
                                                      },
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.form.gross,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "gross",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.gross",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c("base-select", {
                                                  attrs: {
                                                    vid: "treasury_id",
                                                    name: "conto",
                                                    label: "Conto",
                                                    options: _vm.treasuries_opt,
                                                    taggable: false,
                                                    multiple: false,
                                                    closeOnSelect: true,
                                                    rules: { required: true },
                                                  },
                                                  model: {
                                                    value: _vm.form.treasury_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "treasury_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.treasury_id",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    type: "hidden",
                                                    name: "title",
                                                  },
                                                  domProps: {
                                                    value: _vm.form.title,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                type: "button",
                                                variant: "lisaweb",
                                                disabled: invalid,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return handleSubmit(
                                                    _vm.onSubmit
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Recupera")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        _c("b", [_vm._v("Acconto Chiuso")]),
                                      ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3394613455
                    ),
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }